import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";


const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  // service: "smtp",
  // pool: true,
  // host: "viper.itsoul.com",
  // port: 465,
  // secure: true, // use TLS

  const sendEmail = async (e) => {
    e.preventDefault();

    // https://api.hotelzillionexecutive.com/send-email/?key=V]UmMNz;ms

    // http://localhost:3001/send-email/?key=V]UmMNz;ms

    const apiUrl =  "https://api.hotelzillionexecutive.com/?key=V]UmMNz;ms"

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(forms),
      });

      if (response.ok) {
        alert("Sent successfully. We will connect with you soon :)");
        // Optionally, reset the form or navigate to a success page
        setForms({
          name: "",
          email: "",
          subject: "",
          phone: "",
          message: "",
        });
      } else {
        console.error("Error sending email");
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        submitHandler(e);
      }}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Name"
            />
            {validator.message("name", forms.name, "required|alpha_space")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your Email"
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Your phone"
            />
            {validator.message("phone", forms.phone, "required|phone")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.subject}
              type="text"
              name="subject"
            >
              <option>Choose a Service</option>
              <option>New Booking</option>
              <option>Feedback</option>
            </select>
            {validator.message("subject", forms.subject, "required")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Message"
          ></textarea>
          {validator.message("message", forms.message, "required")}
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn" onClick={sendEmail}>
          Submit Now
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
