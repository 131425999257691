import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../../api/service'
import usIcon from '../../images/landmarks/usa.svg'
import bkcIcon from '../../images/landmarks/bkc.svg'
import railwaystationIcon from '../../images/landmarks/railway-station.svg'
import mallIcon from '../../images/landmarks/phoenix-mall.svg'
import airportIcon from '../../images/landmarks/airport.svg'

const WhyChoose = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
    }

    const Landmarks = [
        {
            title: "US Consulate",
            description: "1.8 km away",
            icon: usIcon
        },
        {
            title: "BKC Bandra Kurla Complex",
            description: "1.5 km away",
            icon: bkcIcon
        },
        {
            title: "LTT Railway Station",
            description: "2.3 km away",
            icon: railwaystationIcon
        },
        {
            title: "Phoenix Market City Mall",
            description: "2.3 km away",
            icon: mallIcon
        },
        {
            title: "Dadar Railway Station",
            description: "8.3 km away",
            icon: railwaystationIcon
        },
        {
            title: "Chhatrapati Shivaji International Mumbai Airport",
            description: "1 km away",
            icon: airportIcon

        },

    ]



    

    return(
        <div className="why-choose-section">
            <h3>Key Landmarks</h3>
            <div className="feature-grids clearfix">
                {Landmarks.map((service, sitem) => (
                    <div className="grid" key={sitem}>
                        <div className="icon">
                             <img src={service.icon} alt="" style={{width: "90px", height: "auto", margin: "10px"}}/>
                        </div>
                        <div className="hover-icon">
                           <img src={service.icon} alt=""/>
                        </div>
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default WhyChoose;