import React from 'react'
import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
import { removeFromCart } from "../../store/actions/action";
import { Link } from 'react-router-dom'
import HeaderTopbar from "../HeaderTopbar";
import whatsAppIcon from '../../images/whatsapp.png'


const Header = (props) => {

  const ClickHandler = () => {
      window.scrollTo(10, 0);
  }


    return (
      <header id="header" className={props.topbarBlock}>
        <HeaderTopbar />
        <div className={`wpo-site-header ${props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link onClick={ClickHandler} className="navbar-brand logo" to="/">
                      <img src={props.Logo} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li className="menu-item-has-children">
                        <Link to="/" >Home</Link>
           
                      </li>
                      <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                      <li><Link onClick={ClickHandler} to="/room">Our Rooms</Link></li>
                      <li><Link onClick={ClickHandler} to="/amenities">Amenities Offered </Link></li>
                      <li><Link onClick={ClickHandler} to="/destination">Exploring City</Link></li>
   
                      {/* <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/">Pages</Link>
                        <ul className="sub-menu">
              
                          <li><Link onClick={ClickHandler} to="/cart">Cart</Link></li>
                          <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li>
                          <li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>
                          <li><Link onClick={ClickHandler} to="/search-result">Search Result</Link></li>
                          <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                          <li><Link onClick={ClickHandler} to="/login">Login</Link></li>
                          <li><Link onClick={ClickHandler} to="/register">Register</Link></li>
                        </ul>
                      </li> */}
                      <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                    </ul>

                  </div>
                </div>
                <div className="col-lg-1 col-md-2 col-2">
                  <div className="header-right">
                    <a href="https://api.whatsapp.com/send?phone=919833645657&text=Hello this is the starting message">
                      <button className="search-toggle-btn" style={{background: "transparent"}}>
                        <img src={whatsAppIcon} alt="" />
                      </button>
                    </a>
                  </div>
                </div>
         
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};
export default connect(mapStateToProps, { removeFromCart })(Header);

