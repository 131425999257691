import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/Logo/logo.png'
import ContactpageSec from '../../components/ContactpageSec/ContactpageSec';
import contactPageBanner from '../../images/banners/ph-contact-us.jpg'


const ContactPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'} banner={contactPageBanner}/> 
            <ContactpageSec/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ContactPage;

