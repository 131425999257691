import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/Logo/logo.png";
import AboutPageBanner from "../../images/ph-about-us.jpg";
import WhyChoose from "./WhyChoose";

import image1 from "../../images/about-img1.jpg"
import image2 from "../../images/about-img2.jpg"

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />
      <PageTitle
        pageTitle={"About Us"}
        pagesub={"About"}
        banner={AboutPageBanner}
      />
      <section class="wpo-about-section section-padding">
        <div class="container">
          <div class="wpo-about-section-wrapper">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 col-12">
                <div class="wpo-about-img">
                  <img
                    src={image1}
                    alt=""
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-12">
                <div class="wpo-about-content">
                  <div class="about-title">
                    <span>Welcome</span>
                    <h4>
                      Nestled near the bustling Bandra Kurla Complex locality of
                      Mumbai, Hotel Zillion Executive beckons with a promise of
                      unparalleled comfort and convenience.
                    </h4>
                  </div>
                  <div class="wpo-about-content-inner">
                    <p>
                      Our well-appointed 3-star establishment boasts a selection
                      of comfortable air-conditioned rooms, each thoughtfully
                      designed to ensure a memorable stay.
                    </p>
                    <p>
                      Experience the epitome of relaxation as you step into your
                      room, complete with a cable flat-screen TV for your
                      entertainment pleasure. The ambiance is further enhanced
                      with amenities like room service, ensuring that your needs
                      are met around the clock. Our dedicated 24-hour front desk
                      is ready to assist you with any inquiries or requests,
                      providing a seamless and responsive service.
                    </p>
                    <p>
                      Connectivity is a priority at Hotel Zillion Executive, and
                      we offer complimentary WiFi throughout the premises,
                      ensuring you stay well-connected during your visit.
                      Whether you're travelling for business or leisure, our
                      high-speed internet ensures you can effortlessly stay in
                      touch with the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="order-lg-2 col-lg-6 col-md-12 col-12">
                <div class="wpo-about-img">
                  <img
                    src={image2}
                    alt=""
                  />
                </div>
              </div>
              <div class="order-lg-1 col-lg-6 col-md-12 col-12">
                <div class="wpo-about-content">
                  <div class="wpo-about-content-inner">
                    <p>
                      Our well-appointed 3-star establishment boasts a selection
                      of comfortable air-conditioned rooms, each thoughtfully
                      designed to ensure a memorable stay.
                    </p>
                    <p>
                      Experience the epitome of relaxation as you step into your
                      room, complete with a cable flat-screen TV for your
                      entertainment pleasure. The ambiance is further enhanced
                      with amenities like room service, ensuring that your needs
                      are met around the clock. Our dedicated 24-hour front desk
                      is ready to assist you with any inquiries or requests,
                      providing a seamless and responsive service.
                    </p>
                    <p>
                      Connectivity is a priority at Hotel Zillion Executive, and
                      we offer complimentary WiFi throughout the premises,
                      ensuring you stay well-connected during your visit.
                      Whether you're travelling for business or leisure, our
                      high-speed internet ensures you can effortlessly stay in
                      touch with the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

   
      <section class="pt-5 service-single-section section-padding">
        <div class="container">
          <div class="row">
            <div class="col col-lg-12 col-12">
              <div class="service-single-content">
                <WhyChoose/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default AboutPage;
