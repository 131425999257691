import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Scrollbar from "../../components/scrollbar";
import { connect } from "react-redux";
import api from "../../api";
import Footer from "../../components/footer";
import Logo from "../../images/Logo/logo.png";
import Rooms from "./rooms";
import RoomDetails from "./RoomDetails";
import RoomSinglePageBanner from "../../images/banners/ph-our-rooms.jpg";

const RoomSinglePage = (props) => {
  const { id } = useParams();

  const productsArray = api();

  const [product, setProduct] = useState([]);

  useEffect(() => {
    setProduct(productsArray.filter((all) => all.id === Number(id)));
  }, [id, productsArray]);

  const item = product[0];

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} Logo={Logo} />

      {item?.title !== undefined ? (
        <PageTitle
          pageTitle={item?.title}
          pagesub={"Room"}
          banner={RoomSinglePageBanner}
        />
      ) : (
        <></>
      )}

      <div className="room-details-section">
        {item ? (
          <div className="room-details-inner">
            <div className="wpo-hotel-details-section">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="wpo-hotel-details-wrap">
                      <div className="wpo-hotel-details-area">
                        <form className="clearfix">
                          <div className="details-sub">
                            <span>BEDS</span>
                            <h2>{item.bedroom}</h2>
                          </div>
                          <div className="details-sub">
                            <span>ROOM SIZE</span>
                            <h2>{item.sqm} sq ft</h2>
                          </div>
                       
                
                          <div className="details-sub">
                            <span>Bathroom</span>
                            <h2>{item.bathroom} Shower bath</h2>
                          </div>
            
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Rooms item={item} />
            <RoomDetails item={item} />
          </div>
        ) : null}
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.data.products,
  };
};

export default connect(mapStateToProps)(RoomSinglePage);
