import simg1 from '../images/amenities-offered/1.jpg'
import simg2 from '../images/amenities-offered/2.jpg'
import simg3 from '../images/amenities-offered/3.jpg'
import simg4 from '../images/amenities-offered/4.jpg'
import simg5 from '../images/amenities-offered/5.jpg'
import simg6 from '../images/amenities-offered/6.jpg'
import simg7 from '../images/amenities-offered/7.jpg'
import simg8 from '../images/amenities-offered/8.jpg'
import simg9 from '../images/amenities-offered/9.jpg'
import simg10 from '../images/amenities-offered/10.jpg'
import simg11 from '../images/amenities-offered/11.jpg'
import simg12 from '../images/amenities-offered/12.jpg'
import simg13 from '../images/amenities-offered/13.jpg'
import simg14 from '../images/amenities-offered/14.jpg'


import icon_wifi from '../images/amenities/wifi-router.svg'
import icon_Shower from '../images/amenities/shower.svg'
import icon_parkingarea from '../images/amenities/parking-area.svg'
import icon_air_conditioner from '../images/amenities/air-conditioner.svg'
import icon_safe from '../images/amenities/safe.svg'
import icon_elevator from '../images/amenities/elevator.svg'
import icon_roomservice from '../images/amenities/room-service.svg'
import icon_tv from '../images/amenities/smart-tv.svg'
import icon_laundry from '../images/amenities/laundry.svg'
import icon_doctor from '../images/amenities/doctor.svg'
import icon_carrental from '../images/amenities/car-rental.svg'
import icon_telephone from '../images/amenities/telephone.svg'
import icon_security from '../images/amenities/security.svg'


const Services = [
    {
        id: '1',
        fIcon: icon_wifi,
        title:'Wi-Fi Facility',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg1,

    },
    {
        id: '2',
        fIcon:icon_parkingarea,
        title:'Free Parking',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg2,
    },
    {
        id: '3',
        fIcon:icon_Shower,
        title:'24 Hours Hot & Cold Water',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg3,
    },
    {
        id: '4',
        fIcon: icon_air_conditioner,
        title:'Fully Air-conditioned',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg4,
    },
    {
        id: '6',
        fIcon:icon_tv,
        title:'Digital Cable with LED TV',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg5,
    },
    {
        id: '7',
        fIcon: icon_elevator,
        title:'Elevator',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg6,
    },
    {
        id: '8',
        fIcon:icon_laundry,
        title:'Laundry on Call',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg7,
    },
    {
        id: '9',
        fIcon:icon_doctor,
        title:'Doctor on Call',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg8,
    },
    {
        id: '10',
        fIcon:icon_roomservice,
        title:'Rooms Service (24 Hours)',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg9,

    },
    {
        id: '11',
        fIcon:icon_telephone,
        title:'Direct Dialing Facility',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg10,

    },
    {
        id: '12',
        fIcon:icon_security,
        title:'24 Hour Security',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg11,

    },
    {
        id: '13',
        fIcon: icon_carrental,
        title:'Car Rental',
        description:'Excepteur sint occaecat proident, sunt in culpa mollit est aborum.',
        simg1:simg12,
    },
]


export default Services


// exports default {Services, amenitiesSlider};