import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about-img1.jpg'

const About3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section-s3 section-padding">
            <div className="container">
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={abimg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <span>Welcome</span>
                                    <h2>Discover Hospitality Where Comfort Meets Luxury</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p>Hotel Zillion Executive, situated near Mumbai's Bandra-Kurla Complex, presents cosy air-conditioned rooms equipped with cable flat-screen TVs. As a 3-star establishment, this hotel offers conveniences like room service, a 24-hour front desk, and complimentary WiFi. Additionally, we provide family rooms for guests seeking spacious accommodations.</p>
                                    <Link className="theme-btn" onClick={ClickHandler} to='/about'>Discover More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default About3;