import simg1 from '../images/amenities-offered/1.jpg'
import simg2 from '../images/amenities-offered/2.jpg'
import simg3 from '../images/amenities-offered/3.jpg'
import simg4 from '../images/amenities-offered/4.jpg'
import simg5 from '../images/amenities-offered/5.jpg'
import simg6 from '../images/amenities-offered/6.jpg'
import simg7 from '../images/amenities-offered/7.jpg'
import simg8 from '../images/amenities-offered/8.jpg'
import simg9 from '../images/amenities-offered/9.jpg'
import simg10 from '../images/amenities-offered/10.jpg'
import simg11 from '../images/amenities-offered/11.jpg'
import simg12 from '../images/amenities-offered/12.jpg'
import simg13 from '../images/amenities-offered/13.jpg'
import simg14 from '../images/amenities-offered/14.jpg'

const amenitiesSlider = [
    {
        title: "24Hrs Security",
        image: simg1
    },
    {
        title: "Latest & Modern",
        image: simg2
    },
    {
        title: "Spacious Lobby",
        image: simg3
    },
    {
        title: "Swanky Interiors",
        image: simg4
    },
    {
        title: "Modern Elevators",
        image: simg5
    },
    {
        title: "Well-spaced Lobby",
        image: simg6
    },
    {
        title: "Standard Rooms",
        image: simg7
    },
    {
        title: "2 Single Bed Super Deluxe Room",
        image: simg8
    },
    {
        title: "1 Double Bed Super Deluxe Room",
        image: simg9
    },
    {
        title: "Executive Room",
        image: simg10
    },
    {
        title: "Family Suite",
        image: simg12
    },
    {
        title: "LED TV with Digital Cable",
        image: simg11
    },
    {
        title: "Modern Toilet",
        image: simg14
    },
  
]

export default amenitiesSlider;