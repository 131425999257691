import React from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/Logo/logo.png'
import Rooms2 from '../../components/Rooms2/Rooms2';
import RoomPageBanner from '../../images/ph-our-rooms.jpg'

const RoomPage =() => {
    return(
        <>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Room'} pagesub={'Room'} banner={RoomPageBanner}/> 
            <Rooms2/>
            <Footer/>
            <Scrollbar/>
        </>
    )
};

export default RoomPage;
