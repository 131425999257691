import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage3 from '../HomePage3';
import AboutPage from '../AboutPage/AboutPage';

import DestinationPage from '../DestinationPage/DestinationPage';
import RoomPage from '../RoomPage';
import RoomSinglePage from '../RoomSinglePage';

import ServicePage from '../ServicePage/ServicePage';

import PricingPage from '../PricingPage/PricingPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import ContactPage from '../ContactPage/ContactPage';

const AllRoute = () => {

  return (
    <div className="App">
      <Router basename="/">
        <Routes >
          <Route path='/' element={<HomePage3 />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/room' element={<RoomPage />} />
          <Route path='/room/:id' element={<RoomSinglePage />} />
          <Route path='/destination' element={<DestinationPage />} />
          <Route path='/amenities' element={<ServicePage />} />
          <Route path='/pricing' element={<PricingPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='*' element={<ErrorPage />} />

        </Routes>
      </Router>

    </div>
  );
}

export default AllRoute;
