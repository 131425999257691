import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = ({banner, pageTitle, pagesub}) => {

    return (
        <div className="wpo-breadcumb-area" style={{backgroundImage: `url(${banner})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-breadcumb-wrap">
                            <h2>{pageTitle}</h2>
                            <ul>
                                <li><Link to="/home">Home</Link></li>
                                <li><span>{pagesub}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageTitle;