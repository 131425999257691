import React from 'react';
import { Link } from 'react-router-dom'
import Destinations from '../../api/destination'
import SectionTitle from '../SectionTitle';

import simg1 from '../../images/service/service-img1.jpg'
import simg2 from '../../images/service/service-img2.jpg'
import simg3 from '../../images/service/service-img3.jpg'


const Destination = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    

    const Services = [
        {
            id: '1',
            title:'Digital Cable with LED TV',
            description:'Immerse yourself in entertainment with our digital cable services accompanied by LED TVs in every room.',
            thumnail:simg1,
    
        },
        {
            id: '2',
            title:'Spacious Luxurious Rooms',
            description:'Experience the epitome of comfort in our spacious, luxurious rooms. Thoughtfully designed for your relaxation, each room is a haven of tranquillity, offering ample space to unwind and rejuvenate.',
            thumnail:simg2,
        },
        {
            id: '3',
            title:'Free Breakfast',
            description:'Enjoy the convenience of a fulfilling breakfast, courtesy of our dedication to enhancing your stay with a nourishing and enjoyable dining experience.',
            thumnail:simg3,
        },
    ]

    return (
        <div className={`wpo-destination-area ${props.dClass}`}>
            <div className="container">
              
                <div className="destination-wrap">
                    <div className="row">
                        {Services.map((item, index) => (
                            <div className="col-lg-4 col-md-6 col-12" key={index}>
                                <div className="destination-item">
                                    <div className="destination-img">
                                        <img src={item.thumnail} alt=""/>
                                    </div>
                                    <div className="destination-content">
                                        {/* <span className="sub">{destination.subTitle}</span> */}
                                        <h2>{item.title}</h2>
                                        <p>{item.description}</p>
                               
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Destination;
