import React, {Fragment} from 'react';
import About3 from '../../components/about3/about3';
import Destination from '../../components/Destination';
import Footer from '../../components/footer';
import Hero from '../../components/hero';
import Navbar from '../../components/Navbar'
import Scrollbar from '../../components/scrollbar'
import ServiceSection2 from '../../components/ServiceSection2/ServiceSection2';
import Logo from '../../images/Logo/logo.png'
import Room2 from '../../components/Rooms2/Rooms2'



const HomePage3 =() => {

    return(
        <Fragment>
            <Navbar hclass={"wpo-header-style-3"} Logo={Logo}/> 
            <Hero/> 
            <About3/> 
            <ServiceSection2/> 
            <Destination dClass={'section-padding'}/> 
            <Room2/>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage3;