import jio_world from '../images/destination/Mumbai/jio-world.jpg'
import bandra_sealink from '../images/destination/Mumbai/bandra-sealink.jpg'
import bandra_band_stand from '../images/destination/Mumbai/bandra-band-stand.jpg'
import bandra_walk_of_fame from '../images/destination/Mumbai/bollywood-walk-of-face.jpg'
import shopping_street from '../images/destination/Mumbai/the-shopping-street-linking-road-bandra.jpg'
import andrews_church from '../images/destination/Mumbai/st.-andrews-church.jpg'
import shreesiddivinayak from '../images/destination/Mumbai/shree-siddivinayak-temple.jpg'
import hajiali from '../images/destination/Mumbai/haji-ali.jpg'
import pheonixpalladium from '../images/destination/Mumbai/pheonix-palladium.jpg'
import sanjaygandhipark from '../images/destination/Mumbai/sanjay-gandhi-natl-park.jpg'
import nehruscience_park from '../images/destination/Mumbai/nehru-science-park.jpg'
import marinedrive from '../images/destination/Mumbai/marine-drive.jpg'
import chowpathybeach from '../images/destination/Mumbai/chowpathy-beach.jpg'
import cst from '../images/destination/Mumbai/cst.jpg'
import gatewayofindia from '../images/destination/Mumbai/gateway-of-india.jpg'


import dSimg1 from '../images/destination-single/img-1.jpg'
import dSimg2 from '../images/destination-single/img-2.jpg'
import dSimg3 from '../images/destination-single/img-3.jpg'
import dSimg4 from '../images/destination-single/img-4.jpg'
import dSimg5 from '../images/destination-single/img-5.jpg'
import dSimg6 from '../images/destination-single/img-6.jpg'



const Destinations = [
    {
        id: '1',
        title:'Jio Convention Centre, BKC',
        subTitle:'Vietnam Sea Beach',
        dimg1:jio_world,
        dSimg:dSimg1,
        price:'200',
    },
    {
        id: '2',
        title:'Bandra Worli Sealink ',
        subTitle:'Paris, France',
        dimg1:bandra_sealink,
        dSimg:dSimg2,
        price:'250',
    },
    {
        id: '3',
        title:'Bandra Promenade',
        subTitle:'Vaadhoo, Maldives',
        dimg1:bandra_band_stand,
        dSimg:dSimg3,
        price:'280',
    },
    {
        id: '4',
        title:'Bollywood Walk of Fame, Bandra',
        subTitle:'Vietnam Sea Beach',
        dimg1: bandra_walk_of_fame,
        dSimg:dSimg4,
        price:'300',
    },
    {
        id: '5',
        title:'The Shopping Street - Linking Road, Bandra',
        subTitle:'Paris, France',
        dimg1:shopping_street,
        dSimg:dSimg5,
        price:'180',
    },
    {
        id: '6',
        title:'St. Andrews Church, Bandra',
        subTitle:'Vaadhoo, Maldives',
        dimg1:andrews_church,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '7',
        title:'Shri Siddhivinayak Temple, Dadar',
        subTitle:'Vaadhoo, Maldives',
        dimg1:shreesiddivinayak,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '8',
        title:'Haji Ali - Mahalakshmi',
        subTitle:'Vaadhoo, Maldives',
        dimg1:hajiali,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '9',
        title:'Phoenix Palladium - Lower Parel',
        subTitle:'Vaadhoo, Maldives',
        dimg1:pheonixpalladium,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '10',
        title:'Sanjay Gandhi National Park - Borivali',
        subTitle:'Vaadhoo, Maldives',
        dimg1:sanjaygandhipark,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '11',
        title:'Nehru Science Center - Worli',
        subTitle:'Vaadhoo, Maldives',
        dimg1:nehruscience_park,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '12',
        title:'Marine Drive',
        subTitle:'Vaadhoo, Maldives',
        dimg1:marinedrive,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '13',
        title:'Chowpatty Beach',
        subTitle:'Vaadhoo, Maldives',
        dimg1:chowpathybeach,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '14',
        title:'Chatrapati Shivaji Maharaj Terminus - CST',
        subTitle:'Vaadhoo, Maldives',
        dimg1:cst,
        dSimg:dSimg6,
        price:'400',
    },
    {
        id: '15',
        title:'Gateway of India - Colaba',
        subTitle:'Vaadhoo, Maldives',
        dimg1:gatewayofindia,
        dSimg:dSimg6,
        price:'400',
    },
]


export default Destinations;